import React, { useRef, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import './orderPhotoTape.css';

function DraggableImage({ src, cachedImages, setZoomedImage }) {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'image',
        item: { src },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }), [src]);

    return (
        <div ref={drag} className="draggable-image" style={{ opacity: isDragging ? 0.5 : 1 }}>
            <img src={cachedImages[src]?.base64} alt="thumbnail" onClick={(e) => { setZoomedImage(cachedImages[src]?.base64) }} />
        </div>
    );
}

export default function OrderPhotoTape({ availableImages, cachedImages, setZoomedImage }) {
    const containerRef = useRef(null);

    let targetScrollLeft = 0;

    const smoothScroll = () => {
        const container = containerRef.current;

        if (!container) return;

        const currentScrollLeft = container.scrollLeft;
        const distance = targetScrollLeft - currentScrollLeft;
        const step = distance * 0.2;

        if (Math.abs(step) > 1) {
            container.scrollLeft += step;
            requestAnimationFrame(smoothScroll);
        } else {
            container.scrollLeft = targetScrollLeft;
        }
    };

    const handleWheel = (e) => {
        const container = containerRef.current;
        if (!container) return;

        const maxScrollLeft = container.scrollWidth - container.clientWidth;

        e.preventDefault();

        targetScrollLeft = Math.max(0, Math.min(targetScrollLeft + e.deltaY, maxScrollLeft));
        requestAnimationFrame(smoothScroll);
    };

    useEffect(() => {
        const container = containerRef.current;

        if (container) {
            container.addEventListener('wheel', handleWheel, { passive: false });
        }
        return () => {
            if (container) {
                container.removeEventListener('wheel', handleWheel);
            }
        };
    }, [containerRef]);

    return (
        <div className="orderPhotoTape" ref={containerRef} style={{ display: `${availableImages.length === 0 ? 'none' : 'flex'}` }}>
            {availableImages.map((src, index) => (
                <DraggableImage key={index} src={src} cachedImages={cachedImages} setZoomedImage={setZoomedImage} />
            ))}
        </div>
    );
}
