import './zoomModal.css'
export default function ZoomModal({ image, closeModal }) {
    function handleClose() {
        closeModal();
    }

    return (
        <div className="modal_overlay" onClick={handleClose}>
            <div className="modal_content" style={{ position: 'relative' }}>
                <img src={image} alt="Zoomed" className="zoomed_image" />
                <button className="modal_close_button">
                    <span className="modal_close_icon">&times;</span>
                </button>
            </div>
        </div>
    );
};