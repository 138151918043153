import { useEffect, useState } from 'react'
import '../style.css'
import SelectProductButton from '../components/SelectProductButton'
import ChangeIsActive from '../components/editTables/ChangeIsActiveButton'
import EditableCell from '../components/editTables/EditableCell'
import AddNewProductButton from '../components/editTables/AddNewProductButton'
import OnAddNewProductButtons from '../components/editTables/OnAddNewProductButtons'
import DeleteProductButton from '../components/editTables/DeleteProductButton'
import DownloadImgButton from '../components/editTables/DownloadImgButton'

export default function TableFor({ productInfo }) {
    const tableType = 'for';
    const [data, setData] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isAddingNewProduct, setIsAddingNewProduct] = useState(false);

    useEffect(() => {
        setData(productInfo[tableType])
    }, [productInfo])

    function TableRow({ el, ind }) {
        return (
            <tr style={el.isNew ? { border: '3px solid #66CC33' } : {}}>
                <td style={{ width: '30px' }}>
                    <SelectProductButton el={el} selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} />
                </td>
                <td style={{ width: '30px' }}>{ind + 1}</td>

                <td style={{ width: '30px' }}>
                    <ChangeIsActive el={el} ind={ind} data={data} setData={setData} tableType={tableType} />
                </td>
                <td style={{ width: '60px' }}>{el.id}</td>

                <EditableCell width={'60px'} type={'title'} data={data} setData={setData} el={el} ind={ind} tableType={tableType} />

                <td style={{ width: '60px' }}>
                    <DownloadImgButton el={el} imgType='img' ind={ind} data={data} setData={setData} tableType={tableType} />
                </td>

                <EditableCell width={'60px'} type={'price'} data={data} setData={setData} el={el} ind={ind} tableType={tableType} />
                <EditableCell width={'62px'} type={'basePrice'} data={data} setData={setData} el={el} ind={ind} tableType={tableType} />

                <td style={{ width: '30px' }}>
                    <DownloadImgButton el={el} imgType='guides_jpeg' ind={ind} data={data} setData={setData} tableType={tableType} />
                </td>
                <td style={{ width: '30px' }}>
                    <DownloadImgButton el={el} imgType='guides_psd' ind={ind} data={data} setData={setData} tableType={tableType} />
                </td>
                <td style={{ width: '30px' }}>
                    <DownloadImgButton el={el} imgType='guides_indd' ind={ind} data={data} setData={setData} tableType={tableType} />
                </td>

                <EditableCell width={'100px'} type={'text1'} data={data} setData={setData} el={el} ind={ind} tableType={tableType} />
                <EditableCell width={'100px'} type={'text2'} data={data} setData={setData} el={el} ind={ind} tableType={tableType} />
                <EditableCell width={'100px'} type={'text3'} data={data} setData={setData} el={el} ind={ind} tableType={tableType} />
                <EditableCell width={'100px'} type={'size'} data={data} setData={setData} el={el} ind={ind} tableType={tableType} />
                <EditableCell width={'317px'} type={'notes'} data={data} setData={setData} el={el} ind={ind} tableType={tableType} />
            </tr>
        )
    }

    return (
        <div id="table2">
            <h3 className="tablesTitle">Формат (FOR)</h3>
            <table id="table_forr">
                <tbody id="forr_tbody">
                    <tr style={{ backgroundColor: '#ECECEC' }}>
                        <td style={{ width: '30px' }}>
                            <AddNewProductButton isAddingNewProduct={isAddingNewProduct} setIsAddingNewProduct={setIsAddingNewProduct} data={data} setData={setData} />
                        </td>
                        <td style={{ width: '30px' }}>
                            <DeleteProductButton selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} data={data} setData={setData} tableType={tableType} />
                        </td>
                        <td style={{ width: '30px' }}>Вкл</td>
                        <td style={{ width: '60px' }}>Арт</td>
                        <td style={{ width: '60px' }}>Имя</td>
                        <td style={{ width: '60px' }}>IMG</td>
                        <td style={{ width: '60px' }}>Цена бумаги</td>
                        <td style={{ width: '62px' }}>Базовая цена</td>
                        <td style={{ width: '30px', fontSize: '10px' }}>Нап Jpeg</td>
                        <td style={{ width: '30px', fontSize: '10px' }}>Нап Psd</td>
                        <td style={{ width: '30px', fontSize: '10px' }}>Нап Indd</td>

                        <td style={{ width: '100px' }}>Текст 1<br />
                            (Над картинкой)</td>
                        <td style={{ width: '100px' }}>Текст 2
                            (Значение)</td>
                        <td style={{ width: '100px' }}>Текст 3 (Параметры)</td>
                        <td style={{ width: '100px' }}>Целевой размер</td>
                        <td style={{ width: '317px' }}>Прим.</td>
                    </tr>
                    {data.map((el, ind) => {
                        return <TableRow el={el} ind={ind} key={`for_${el.id}`} />
                    })}
                </tbody>
            </table>
            <OnAddNewProductButtons isAddingNewProduct={isAddingNewProduct} setIsAddingNewProduct={setIsAddingNewProduct} data={data} setData={setData} tableType={tableType} />
        </div>
    )
}
