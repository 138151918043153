import React from 'react';
import { useDrop, useDrag } from 'react-dnd';
import './droppableArea.css';

export default function DroppableArea({ onDrop, id, currentImage, currentImageUrl, onReplace, onDelete, onZoom, size }) {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'image',
        item: { id, src: currentImageUrl },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            if (monitor.didDrop()) {
                onDelete(id);
            }
        }
    }), [currentImage]);

    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'image',
        drop: (item) => {
            if (item.id !== id) {
                if (currentImage) {
                    onReplace(currentImageUrl, id);
                }
                onDrop(item.src, id);
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    }), [id, onDrop, currentImage, onReplace]);

    function getSize() {
        if (!size) {
            return
        }
        const [width, height] = size.split('x').map(Number);

        const newWidth = width / 20;
        const newHeight = height / 20;

        const maxSize = 350;

        if (newWidth > maxSize || newHeight > maxSize) {
            const scale = Math.max(newWidth, newHeight) / maxSize;
            return {
                width: newWidth / scale,
                height: newHeight / scale
            };
        }

        return {
            width: newWidth,
            height: newHeight
        };
    }


    return (
        <div
            ref={drop}
            className="innerCardItemBlockRetangle droppable"
            style={{
                backgroundColor: isOver ? 'lightgreen' : 'white',
                opacity: isDragging ? 0.5 : 1,
                width: getSize()?.width,
                height: getSize()?.height
            }}
        >
            {currentImage ? (
                <img
                    ref={drag}
                    src={currentImage?.base64}
                    alt="Dropped"
                    className="dropped-image"
                    onClick={() => { onZoom(id) }}
                />
            ) : (
                <p>Перетащите изображение сюда</p>
            )}
        </div>
    );
}
