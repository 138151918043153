import './pageCard.css'
import React, { useEffect } from 'react';
import DroppableArea from '../DroppableArea/DroppableArea';

export default function PageCard({
    mode,
    ind,
    id,
    currentImage,
    currentImageUrl,
    onCopy,
    onCopyAll,
    onDrop,
    onReplace,
    onDelete,
    onZoom,
    coverSize,
    blockSize
}) {

    function getPageName(ind) {
        if (ind === 0) {
            return 'Обложка'
        }
        return `Разворот ${ind}`
    }


    return (
        <div className="innerCardItemBlock" style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            <p className="innerCardItemBlockTitle">{getPageName(ind)}</p>
            <DroppableArea
                id={id}
                currentImage={currentImage}
                currentImageUrl={currentImageUrl}
                onDrop={onDrop}
                onReplace={onReplace}
                onDelete={onDelete}
                onZoom={onZoom}
                size={ind === 0 ? coverSize : blockSize}
            />
            <p className="innerCardItemBlockName">{currentImage?.name}</p>
            <div className="innerCardItemBlockButtons">
                <button
                    className={`${mode !== 'copyButtons' && 'disabledButton'} innerCardItemBlockButton buttonCopy`}
                    onClick={() => { onCopy(id) }}
                />
                <button
                    className={`${mode !== 'copyButtons' && 'disabledButton'} innerCardItemBlockButton buttonCopyAll`}
                    onClick={() => { onCopyAll(id) }}
                />

                <button
                    className="innerCardItemBlockButton buttonDelete"
                    onClick={() => onDelete(id)}
                />
                <button
                    className="innerCardItemBlockButton buttonZoom"
                    onClick={() => onZoom(id)}
                />
            </div>
        </div>
    );
}
