import './bookCard.css'
import React from 'react';
import PageCard from '../PageCard/PageCard';

export default function BookCard({
    ind,
    mode,
    pages,
    onClearBook,
    onCopy,
    onCopyAll,
    onDrop,
    onReplace,
    onDelete,
    onZoom,
    cachedImages,
    coverSize,
    blockSize,
}) {
    return (
        <div className="innerCard">
            <div className="innerCardItem">
                <div className="innerCardItemTitle">
                    <span>{`Книга ${ind + 1}`}</span>
                    <button className="innerCardItemTitleClose" onClick={() => { onClearBook(ind); }}></button>
                </div>

                <div className="innerCardOrderItemContent">
                    {pages.map((page, ind) => (
                        <PageCard
                            key={ind}
                            mode={mode}
                            ind={ind}
                            id={page.id}
                            currentImage={cachedImages[page.currentImage]}
                            currentImageUrl={page.currentImage}
                            onCopy={onCopy}
                            onCopyAll={onCopyAll}
                            onDrop={onDrop}
                            onReplace={onReplace}
                            onDelete={onDelete}
                            onZoom={onZoom}
                            coverSize={coverSize}
                            blockSize={blockSize}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}
